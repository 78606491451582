<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <div class="connects-wrapper">
        <div v-for="item in items" :key="item.id" class="single-connect-wrapper">
          <div class="mb-4">
            <h2>{{item.domain_data.title}}</h2>
            <h4>{{item.domain}}</h4>
          </div>
          <div>
            <a :href="`http://${item.domain}`" target="_blank">
              <v-btn block color="primary" class="mt-2 mb-2">Visit</v-btn>
            </a>
            <v-btn block color="primary" class="mt-2 mb-2" v-on:click="editItem(item)">Website Details</v-btn>
            <v-btn block color="primary" class="mt-2 mb-2" v-on:click="$router.push(`/connect-experts/${item.id}`)">Experts</v-btn>
            <v-btn block color="primary" class="mt-2 mb-2" v-on:click="$router.push(`/connect-programmes/${item.id}`)">Programs</v-btn>
            <v-btn block color="primary" class="mt-2 mb-2" v-on:click="editItemCollaborations(item)">Collaborations</v-btn>
            <v-btn block color="primary" class="mt-2 mb-2" v-on:click="editItemContactInformation(item)">Contact info</v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-form
          ref="form"
          @submit.prevent="save()"
          v-model="valid"
          action="/"
        >
          <v-card-text>
            <v-container>
              <!-- WEBSITE -->
              <h2 class="mt-6 mb-6">WEBSITE</h2>
              <v-row v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'">
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.title"
                  label="Page Name"
                ></v-text-field>
              </v-row>
              <v-row v-else>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.title"
                  label="Title"
                ></v-text-field>
              </v-row>
              <h2 class="mt-6 mb-6">HOMEPAGE</h2>
              <v-row v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'">
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.main_title"
                  label="Main Title"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-textarea
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.description"
                  label="Main Description"
                ></v-textarea>
              </v-row>
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.domain_data.home_tab_title"
                      label="SEO - Page title (shown in browser tab)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>SEO Page title - This is the title of the tab of the page and also shows up
                    <br>in search engines as the main title for the page.
                    <br>Keep the title short (max. 60 characters) and
                    <br>utilise keywords from the page.
                  </span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-textarea
                    v-model="editedItem.domain_data.home_og_meta_desc"
                    v-bind="attrs"
                    v-on="on"
                    label="SEO - Meta description (this description will appear on google search results)"
                  ></v-textarea>
                  </template>
                  <span>The meta description is shown in search engines and
                    <br>is used to describe the contents of the page.
                    <br>Keep it between 50-160 characters and try to
                    <br>imagine what a user would search for in order
                    <br>to find the contents of the page.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- CONNECTS PAGE -->
              <h2 v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'" class="mt-6 mb-6">CONNECTS PAGE</h2>
              <v-row v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'">
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.main_section_title"
                  label="Section title"
                ></v-text-field>
              </v-row>
              <v-row v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'">
                <v-textarea
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.main_section_description"
                  label="Section description"
                ></v-textarea>
              </v-row>
              <v-row v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.domain_data.connects_tab_title"
                      label="SEO - Page title (shown in browser tab)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>SEO Page title - This is the title of the tab of the page and also shows up
                    <br>in search engines as the main title for the page.
                    <br>Keep the title short (max. 60 characters) and
                    <br>utilise keywords from the page.
                  </span>
                </v-tooltip>
              </v-row>
              <v-row v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-textarea
                    v-model="editedItem.domain_data.connects_og_meta_desc"
                    v-bind="attrs"
                    v-on="on"
                    label="SEO - Meta description (this description will appear on google search results)"
                  ></v-textarea>
                  </template>
                  <span>The meta description is shown in search engines and
                    <br>is used to describe the contents of the page.
                    <br>Keep it between 50-160 characters and try to
                    <br>imagine what a user would search for in order
                    <br>to find the contents of the page.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- MEET THE EXPERTS PAGE -->
              <h2 v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'" class="mt-6 mb-6">MEET THE EXPERTS PAGE</h2>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.domain_data.meet_the_experts_tab_title"
                      label="SEO - Page title (shown in browser tab)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>SEO Page title - This is the title of the tab of the page and also shows up
                    <br>in search engines as the main title for the page.
                    <br>Keep the title short (max. 60 characters) and
                    <br>utilise keywords from the page.
                  </span>
                </v-tooltip>
              </v-row>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-textarea
                    v-model="editedItem.domain_data.meet_the_experts_og_meta_desc"
                    v-bind="attrs"
                    v-on="on"
                    label="SEO - Meta description (this description will appear on google search results)"
                  ></v-textarea>
                  </template>
                  <span>The meta description is shown in search engines and
                    <br>is used to describe the contents of the page.
                    <br>Keep it between 50-160 characters and try to
                    <br>imagine what a user would search for in order
                    <br>to find the contents of the page.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- PARTNERSHIPS PAGE -->
              <h2 v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'" class="mt-6 mb-6">PARTNERSHIPS PAGE</h2>
              <v-row v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.domain_data.partnerships_tab_title"
                      label="SEO - Page title (shown in browser tab)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>SEO Page title - This is the title of the tab of the page and also shows up
                    <br>in search engines as the main title for the page.
                    <br>Keep the title short (max. 60 characters) and
                    <br>utilise keywords from the page.
                  </span>
                </v-tooltip>
              </v-row>
              <v-row v-if="editedItem.domain === 'stage.cor2ed.xyz' || editedItem.domain === 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-textarea
                    v-model="editedItem.domain_data.partnerships_og_meta_desc"
                    v-bind="attrs"
                    v-on="on"
                    label="SEO - Meta description (this description will appear on google search results)"
                  ></v-textarea>
                  </template>
                  <span>The meta description is shown in search engines and
                    <br>is used to describe the contents of the page.
                    <br>Keep it between 50-160 characters and try to
                    <br>imagine what a user would search for in order
                    <br>to find the contents of the page.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- COLLABORATIONS PAGE -->
              <h2 v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'" class="mt-6 mb-6">COLLABORATIONS PAGE</h2>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.collaboration_title"
                  label="Collaboration title"
                ></v-text-field>
              </v-row>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-textarea
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.collaboration_description"
                  label="Collaboration description"
                ></v-textarea>
              </v-row>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.domain_data.collaboration_tab_title"
                      label="SEO - Page title (shown in browser tab)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>SEO Page title - This is the title of the tab of the page and also shows up
                    <br>in search engines as the main title for the page.
                    <br>Keep the title short (max. 60 characters) and
                    <br>utilise keywords from the page.
                  </span>
                </v-tooltip>
              </v-row>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-textarea
                    v-model="editedItem.domain_data.collaboration_og_meta_desc"
                    v-bind="attrs"
                    v-on="on"
                    label="SEO - Meta description (this description will appear on google search results)"
                  ></v-textarea>
                  </template>
                  <span>The meta description is shown in search engines and
                    <br>is used to describe the contents of the page.
                    <br>Keep it between 50-160 characters and try to
                    <br>imagine what a user would search for in order
                    <br>to find the contents of the page.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- EDUCATIONAL PROGRAMS PAGE -->
              <h2 v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'" class="mt-6 mb-6">EDUCATIONAL PROGRAMS PAGE</h2>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.educational_programe_title"
                  label="Educational programs title"
                ></v-text-field>
              </v-row>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-textarea
                  :rules="[rules.required]"
                  v-model="editedItem.domain_data.educational_programe_description"
                  label="Educational programs description"
                ></v-textarea>
              </v-row>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.domain_data.educational_programs_tab_title"
                      label="SEO - Page title (shown in browser tab)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>SEO Page title - This is the title of the tab of the page and also shows up
                    <br>in search engines as the main title for the page.
                    <br>Keep the title short (max. 60 characters) and
                    <br>utilise keywords from the page.
                  </span>
                </v-tooltip>
              </v-row>
              <v-row v-if="editedItem.domain !== 'stage.cor2ed.xyz' && editedItem.domain !== 'cor2ed.com'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-textarea
                    v-model="editedItem.domain_data.educational_programs_og_meta_desc"
                    v-bind="attrs"
                    v-on="on"
                    label="SEO - Meta description (this description will appear on google search results)"
                  ></v-textarea>
                  </template>
                  <span>The meta description is shown in search engines and
                    <br>is used to describe the contents of the page.
                    <br>Keep it between 50-160 characters and try to
                    <br>imagine what a user would search for in order
                    <br>to find the contents of the page.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- DISCLAIMER -->
              <h2 class="mt-6 mb-6">DISCLAIMER</h2>
              <v-row>
                <span style="width: 100%" class="mt-8">Disclaimer</span>
                <editor
                  style="width: 100%"
                  v-if="editorReady"
                  v-model="editedItem.domain_data.disclaimer"
                  api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                  :disabled="false"
                  initial-value=""
                  :init="{
                    images_upload_url: `${baseURL}/api/image-upload`,
                    height: 500,
                    menubar: false,
                    document_base_url: `${baseURL}/`,
                    relative_urls: false,
                    remove_script_host: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help \
                      link \
                      media image',
                  }"
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text type="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="contactInformationDialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Contact information</span>
        </v-card-title>
        <v-form
          ref="form"
          @submit.prevent="saveContactInformation()"
          v-model="valid"
          action="/"
        >
          <v-card-text>
            <v-container v-if="editedItem.website_contact">
              <v-row>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.website_contact.title"
                  label="Title"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.website_contact.tab_title"
                      label="SEO - Page title (shown in browser tab)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>SEO Page title - This is the title of the tab of the page and also shows up
                    <br>in search engines as the main title for the page.
                    <br>Keep the title short (max. 60 characters) and
                    <br>utilise keywords from the page.
                  </span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.website_contact.og_meta_desc"
                    v-bind="attrs"
                    v-on="on"
                    label="SEO - meta description (this description will appear on google search results)"
                  ></v-text-field>
                  </template>
                  <span>The meta description is shown in search engines and
                    <br>is used to describe the contents of the page.
                    <br>Keep it between 50-160 characters and try to
                    <br>imagine what a user would search for in order
                    <br>to find the contents of the page.
                  </span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.website_contact.link_1"
                  label="LinkedIn link"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.website_contact.link_2"
                  label="Twitter link"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.website_contact.address_1"
                  label="Address street"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.website_contact.address_2"
                  label="Address city"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.website_contact.address_3"
                  label="Address country"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.website_contact.more_info_title"
                  label="More information text"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="editedItem.website_contact.more_info_url"
                  label="More information URL"
                ></v-text-field>
              </v-row>
              <!-- CONTACT PERSON 1 START -->
              <v-row>
                <v-switch
                  v-model="contactPerson_1"
                  label="Contact person #1"
                />
              </v-row>
              <v-row v-if="contactPerson_1">
                <v-text-field
                  v-model="editedItem.website_contact.full_name_1"
                  label="Prefix and Full name"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_1">
                <v-text-field
                  v-model="editedItem.website_contact.institution_1"
                  label="Institution"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_1">
                <v-text-field
                  v-model="editedItem.website_contact.email_1"
                  label="Email"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_1">
                <v-file-input
                  accept="image/* //"
                  placeholder="Pick an image"
                  v-model="editedItem.website_contact.image_1"
                  :input="loadImage_1()"
                  label="Image"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                </v-file-input>
                <div style="width: 100%">
                  <img
                    v-if="contactPerson_1_image"
                    :src="getImage(contactPerson_1_image)"
                    height="100"
                    class="mb-4"
                  />
                </div>
              </v-row>
              <v-row v-if="contactPerson_1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      v-model="editedItem.website_contact.alt_image_1"
                      label="SEO - Alt image"
                    ></v-text-field>
                  </template>
                  <span>The Alt tags are used to describe the image to
                  <br>search engines (cannot be seen by the user).
                  <br>Try to create a simple description of the image
                  <br>as if you were describing it to someone with their eyes closed.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- CONTACT PERSON_2 START -->
              <v-row v-if="contactPerson_1">
                <v-switch
                  v-model="contactPerson_2"
                  label="Contact person #2"
                />
              </v-row>
              <v-row v-if="contactPerson_2">
                <v-text-field
                  v-model="editedItem.website_contact.full_name_2"
                  label="Prefix and Full name"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_2">
                <v-text-field
                  v-model="editedItem.website_contact.institution_2"
                  label="Institution"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_2">
                <v-text-field
                  v-model="editedItem.website_contact.email_2"
                  label="Email"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_2">
                <v-file-input
                  accept="image/* //"
                  placeholder="Pick an image"
                  v-model="editedItem.website_contact.image_2"
                  :input="loadImage_2()"
                  label="Image"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                </v-file-input>
                <div style="width: 100%">
                  <img
                    v-if="contactPerson_2_image"
                    :src="getImage(contactPerson_2_image)"
                    height="100"
                    class="mb-4"
                  />
                </div>
              </v-row>
              <v-row v-if="contactPerson_2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      v-model="editedItem.website_contact.alt_image_2"
                      label="SEO - Alt image"
                    ></v-text-field>
                  </template>
                  <span>The Alt tags are used to describe the image to
                  <br>search engines (cannot be seen by the user).
                  <br>Try to create a simple description of the image
                  <br>as if you were describing it to someone with their eyes closed.
                  </span>
                </v-tooltip>
              </v-row>
              <!-- CONTACT PERSON_3 START -->
              <v-row v-if="contactPerson_1 && contactPerson_2">
                <v-switch
                  v-model="contactPerson_3"
                  label="Contact person #3"
                />
              </v-row>
              <v-row v-if="contactPerson_3">
                <v-text-field
                  v-model="editedItem.website_contact.full_name_3"
                  label="Prefix and Full name"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_3">
                <v-text-field
                  v-model="editedItem.website_contact.institution_3"
                  label="Institution"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_3">
                <v-text-field
                  v-model="editedItem.website_contact.email_3"
                  label="Email"
                ></v-text-field>
              </v-row>
              <v-row v-if="contactPerson_3">
                <v-file-input
                  accept="image/* //"
                  placeholder="Pick an image"
                  v-model="editedItem.website_contact.image_3"
                  :input="loadImage_3()"
                  label="Image"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                </v-file-input>
                <div style="width: 100%">
                  <img
                    v-if="contactPerson_3_image"
                    :src="getImage(contactPerson_3_image)"
                    height="100"
                    class="mb-4"
                  />
                </div>
              </v-row>
              <v-row v-if="contactPerson_3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      v-model="editedItem.website_contact.alt_image_3"
                      label="SEO - Alt image"
                    ></v-text-field>
                  </template>
                  <span>The Alt tags are used to describe the image to
                  <br>search engines (cannot be seen by the user).
                  <br>Try to create a simple description of the image
                  <br>as if you were describing it to someone with their eyes closed.
                  </span>
                </v-tooltip>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeContactInformationDialog">Cancel</v-btn>
            <v-btn color="blue darken-1" text type="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="collaborationsDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Select collaborations for<br>{{editedItem.domain_data.title}}</span>
        </v-card-title>
        <v-form
          ref="form"
          @submit.prevent="saveCollaborations()"
          v-model="valid"
          action="/"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-text-field
                  v-model="searchString"
                  label="Search"
                ></v-text-field>
              </v-row>
              <v-row v-if="filteredCollaborations.length > 0">
                <div
                  style="width: 100%"
                  v-for="collaboration in filteredCollaborations"
                  :key="collaboration.id"
                  class="mt-0 mb-0"
                >
                  <v-switch
                    :label="collaboration.title"
                    v-model="collaboration.selected"
                  ></v-switch>
                    
                  <!-- {{collaboration.title}} -->
                </div>
              </v-row>
              <v-row v-else>
                No collaborations found...
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeCollaborationsDialog">Cancel</v-btn>
            <v-btn color="blue darken-1" text type="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  fetchWebsites,
  editWebsite,
  deleteWebsite,
  fetchCollaborations,
  editWebsiteCollaborations,
  editWebsiteContactInformation,
  editWebsiteMedicalAffairs
} from "@/services";
import TheSidebar from "@/components/TheSidebar"

export default {
  name: "Connects",
  components: {
    TheSidebar,
  },
  data() {
    return {
      searchString: '',
      spinnerActive: false,
      editorReady: false,
      items: [],
      collaborations: [],
      dialog: false,
      fileName_1: null,
      fileName_2: null,
      fileName_3: null,
      collaborationsDialog: false,
      medicalAffairsdialog: false,
      contactPerson_1: false,
      contactPerson_2: false,
      contactPerson_3: false,
      contactPerson_1_image: null,
      contactPerson_2_image: null,
      contactPerson_3_image: null,
      medical_affairs_image_1: null,
      medical_affairs_image_2: null,
      medical_affairs_image_3: null,
      medical_affairs_image_4: null,
      medical_affairs_image_5: null,
      medical_affairs_image_6: null,
      valid: false,
      baseURL: '',
      contactInformationDialog: false,
      imageUpdated_1: false,
      imageUpdated_2: false,
      imageUpdated_3: false,
      imageUpdated_ma_1: false,
      imageUpdated_ma_2: false,
      imageUpdated_ma_3: false,
      imageUpdated_ma_4: false,
      imageUpdated_ma_5: false,
      imageUpdated_ma_6: false,
      editedIndex: -1,
      editedItem: { domain_data: { title: "", description: "", disclaimer: "" } },
      defaultItem: { domain_data: { title: "", description: "", disclaimer: "" } },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 20 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item"
    },
    filteredCollaborations () {
      const searchString = this.searchString.toLowerCase()
      if (!searchString) return this.collaborations
      return this.collaborations.filter(item => {
        const text = item.title.toLowerCase()
        return text.indexOf(searchString) > -1
      })
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    collaborationsDialog(val) {
      val || this.closeCollaborationsDialog()
    },
    contactInformationDialog(val) {
      val || this.closeContactInformationDialog()
    },
    medicalAffairsdialog(val) {
      val || this.closeMedicalAffairsDialog()
    }
  },
  mounted() {
    this.baseURL = window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz' : window.location.origin
    this.getWebsites()
    this.getCollaborations()
  },
  methods: {
    loadImage_1() {
      if (this.editedItem.website_contact.image_1 && this.editedItem.website_contact.image_1.size) {
        var file = this.editedItem.website_contact.image_1
        var reader = new FileReader()
        this.fileName_1 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.contactPerson_1_image = reader.result
            this.imageUpdated_1 = true
            this.editedItem.website_contact.image_1 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    loadImage_2() {
      if (this.editedItem.website_contact.image_2 && this.editedItem.website_contact.image_2.size) {
        var file = this.editedItem.website_contact.image_2
        var reader = new FileReader()
        this.fileName_2 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.contactPerson_2_image = reader.result
            this.imageUpdated_2 = true
            this.editedItem.website_contact.image_2 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    loadImage_3() {
      if (this.editedItem.website_contact.image_3 && this.editedItem.website_contact.image_3.size) {
        var file = this.editedItem.website_contact.image_3
        var reader = new FileReader()
        this.fileName_3 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.contactPerson_3_image = reader.result
            this.imageUpdated_3 = true
            this.editedItem.website_contact.image_3 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_1() {
      if (this.editedItem.website_affair && this.editedItem.website_affair.medical_affairs_image_1 && this.editedItem.website_affair.medical_affairs_image_1.size) {
        var file = this.editedItem.website_affair.medical_affairs_image_1
        var reader = new FileReader()
        this.fileName_ma_1 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_1 = reader.result
            this.imageUpdated_ma_1 = true
            this.editedItem.website_affair.medical_affairs_image_1 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_2() {
      if (this.editedItem.website_affair && this.editedItem.website_affair.medical_affairs_image_2 && this.editedItem.website_affair.medical_affairs_image_2.size) {
        var file = this.editedItem.website_affair.medical_affairs_image_2
        var reader = new FileReader()
        this.fileName_ma_2 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_2 = reader.result
            this.imageUpdated_ma_2 = true
            this.editedItem.website_affair.medical_affairs_image_2 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_3() {
      if (this.editedItem.website_affair && this.editedItem.website_affair.medical_affairs_image_3 && this.editedItem.website_affair.medical_affairs_image_3.size) {
        var file = this.editedItem.website_affair.medical_affairs_image_3
        var reader = new FileReader()
        this.fileName_ma_3 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_3 = reader.result
            this.imageUpdated_ma_3 = true
            this.editedItem.website_affair.medical_affairs_image_3 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_4() {
      if (this.editedItem.website_affair && this.editedItem.website_affair.medical_affairs_image_4 && this.editedItem.website_affair.medical_affairs_image_4.size) {
        var file = this.editedItem.website_affair.medical_affairs_image_4
        var reader = new FileReader()
        this.fileName_ma_4 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_4 = reader.result
            this.imageUpdated_ma_4 = true
            this.editedItem.website_affair.medical_affairs_image_4 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_5() {
      if (this.editedItem.website_affair && this.editedItem.website_affair.medical_affairs_image_5 && this.editedItem.website_affair.medical_affairs_image_5.size) {
        var file = this.editedItem.website_affair.medical_affairs_image_5
        var reader = new FileReader()
        this.fileName_ma_5 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_5 = reader.result
            this.imageUpdated_ma_5 = true
            this.editedItem.website_affair.medical_affairs_image_5 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    medicalAffairsLoadImage_6() {
      if (this.editedItem.website_affair && this.editedItem.website_affair.medical_affairs_image_6 && this.editedItem.website_affair.medical_affairs_image_6.size) {
        var file = this.editedItem.website_affair.medical_affairs_image_6
        var reader = new FileReader()
        this.fileName_ma_6 = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.medical_affairs_image_6 = reader.result
            this.imageUpdated_ma_6 = true
            this.editedItem.website_affair.medical_affairs_image_6 = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    getImage(imageName) {
      if (imageName && imageName.includes("data:image")) {
        return imageName  
      } else {
        return window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz/api/image/' + imageName : window.location.origin + '/api/image/' + imageName
      }
    },
    async getCollaborations() {
      this.collaborations = []
      setTimeout(async () => {
        let tableData = {}
        tableData["perPage"] = this.perPage
        tableData["sortBy"] = this.sortBy
        tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC"
        tableData["page"] = this.page
        this.spinnerActive = true
        await fetchCollaborations(tableData).then((res) => {
          if (res.data) {
            this.collaborations = res.data.data.data
            this.collaborations.forEach(element => {
              element['selected'] = false
            });
            this.spinnerActive = false
            this.total = res.data.data.total
          }
        }).catch((error) => {
          console.log(error)
          this.spinnerActive = false
        })
      }, 0)
    },
    async getWebsites() {
      this.spinnerActive = true
      await fetchWebsites()
        .then((res) => {
          if (res.data) {
            this.items = res.data
            this.spinnerActive = false
          }
        })
        .catch((error) => {
          console.log(error)
          this.spinnerActive = false
        })
    },
    addItem() {
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
    },
    editItem(item) {
      this.editedIndex = 1
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      setTimeout(() => {
        this.editorReady = true
      }, 200)
    },
    editItemCollaborations(item) {
      this.editedIndex = 1
      this.editedItem = Object.assign({}, item)
      this.collaborations.forEach(all_el => {
        all_el.selected = item.collaborations.some(el => el.id === all_el.id)
      });
      this.collaborationsDialog = true
    },
    editItemContactInformation(item) {
      this.editedIndex = 1
      if (item.website_contact) {
        if (item.website_contact.full_name_1) { this.contactPerson_1 = true }
        if (item.website_contact.full_name_2) { this.contactPerson_2 = true }
        if (item.website_contact.full_name_3) { this.contactPerson_3 = true }
        if (item.website_contact.image_1) { this.contactPerson_1_image = item.website_contact.image_1; item.website_contact.image_1 = null }
        if (item.website_contact.image_2) { this.contactPerson_2_image = item.website_contact.image_2; item.website_contact.image_2 = null }
        if (item.website_contact.image_3) { this.contactPerson_3_image = item.website_contact.image_3; item.website_contact.image_3 = null }
      }
      this.editedItem = Object.assign({}, item)
      this.contactInformationDialog = true
      setTimeout(() => {
        this.editorReady = true
      }, 200)
    },
    editItemMedicalAffairs(item) {
      this.editedIndex = 1
      if (item.website_affair) {
        if (item.website_affair.medical_affairs_image_1) {
          this.medical_affairs_image_1 = item.website_affair.medical_affairs_image_1
          item.website_affair.medical_affairs_image_1 = null 
        }
        if (item.website_affair.medical_affairs_image_2) {
          this.medical_affairs_image_2 = item.website_affair.medical_affairs_image_2
          item.website_affair.medical_affairs_image_2 = null 
        }
        if (item.website_affair.medical_affairs_image_3) {
          this.medical_affairs_image_3 = item.website_affair.medical_affairs_image_3
          item.website_affair.medical_affairs_image_3 = null 
        }
        if (item.website_affair.medical_affairs_image_4) {
          this.medical_affairs_image_4 = item.website_affair.medical_affairs_image_4
          item.website_affair.medical_affairs_image_4 = null 
        }
        if (item.website_affair.medical_affairs_image_5) {
          this.medical_affairs_image_5 = item.website_affair.medical_affairs_image_5
          item.website_affair.medical_affairs_image_5 = null 
        }
        if (item.website_affair.medical_affairs_image_6) {
          this.medical_affairs_image_6 = item.website_affair.medical_affairs_image_6
          item.website_affair.medical_affairs_image_6 = null 
        }
      }
      this.editedItem = Object.assign({}, item)
      this.medicalAffairsdialog = true
      setTimeout(() => {
        this.editorReady = true
      }, 200)
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    close() {
      this.dialog = false
      this.collaborations.forEach(element => {
        element['selected'] = false
      });
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeCollaborationsDialog() {
      this.collaborationsDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editorReady = false
      })
    },
    closeContactInformationDialog() {
      this.contactInformationDialog = false
      this.contactPerson_1 = false
      this.contactPerson_2 = false
      this.contactPerson_3 = false
      this.contactPerson_1_image = null
      this.contactPerson_2_image = null
      this.contactPerson_3_image = null
      this.fileName_1 = false
      this.fileName_2 = false
      this.fileName_3 = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editorReady = false
      })
    },
    closeMedicalAffairsDialog() {
      this.medicalAffairsdialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editorReady = false
        this.medical_affairs_image_1 = null
        this.medical_affairs_image_2 = null
        this.medical_affairs_image_3 = null
        this.medical_affairs_image_4 = null
        this.medical_affairs_image_5 = null
        this.medical_affairs_image_6 = null
        this.fileName_ma_1 = null
        this.fileName_ma_2 = null
        this.fileName_ma_3 = null
        this.fileName_ma_4 = null
        this.fileName_ma_5 = null
        this.fileName_ma_6 = null
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    async deleteItemConfirm() {
      await deleteWebsite().then(() => {
          this.closeDelete()
        }).catch((error) => {
          console.log(error)
        })
      this.close()
    },
    async saveCollaborations() {
      let data = []
      this.collaborations.forEach(el => {
        let x = {
          website_id: this.editedItem.id,
          collaboration_id: el.id,
          status: el.selected ? 1 : 0
        }
        data.push(x)
      })
      await editWebsiteCollaborations({ data: data }).then(() => {
        this.getWebsites()
        this.closeCollaborationsDialog()
      })
      .then((error) => {
        console.log("editWebsite error => ", error)
      })
    },
    async saveMedicalAffairs() {
      let data = this.editedItem.website_affair
      data.id = data.website_id

      data.medical_affairs_image_1 = this.medical_affairs_image_1
      data["file_name_1"] = this.fileName_ma_1
      data.medical_affairs_image_2 = this.medical_affairs_image_2
      data["file_name_2"] = this.fileName_ma_2
      data.medical_affairs_image_3 = this.medical_affairs_image_3
      data["file_name_3"] = this.fileName_ma_3
      data.medical_affairs_image_4 = this.medical_affairs_image_4
      data["file_name_4"] = this.fileName_ma_4
      data.medical_affairs_image_5 = this.medical_affairs_image_5
      data["file_name_5"] = this.fileName_ma_5
      data.medical_affairs_image_6 = this.medical_affairs_image_6
      data["file_name_6"] = this.fileName_ma_6

      await editWebsiteMedicalAffairs(data).then(() => {
        this.getWebsites()
        this.closeMedicalAffairsDialog()
      }).catch((error) => {
        console.log("editWebsite error => ", error)
      })
    },
    async saveContactInformation() {
      let data = this.editedItem.website_contact
      data.id = data.website_id
      if (!this.contactPerson_1) {
        data.email_1 = null
        data.full_name_1 = null
        data.image_1 = null
        data.alt_image_1 = null
        data.institution_1 = null
      } else {
        data.image_1 = this.contactPerson_1_image
        data["file_name_1"] = this.fileName_1
      }
      if (!this.contactPerson_2) {
        data.email_2 = null
        data.full_name_2 = null
        data.image_2 = null
        data.alt_image_2 = null
        data.institution_2 = null
      } else {
        data.image_2 = this.contactPerson_2_image
        data["file_name_2"] = this.fileName_2
      }
      if (!this.contactPerson_3) {
        data.email_3 = null
        data.full_name_3 = null
        data.image_3 = null
        data.alt_image_3 = null
        data.institution_3 = null
      } else {
        data.image_3 = this.contactPerson_3_image
        data["file_name_3"] = this.fileName_3
      }
      await editWebsiteContactInformation(data).then(() => {
        this.getWebsites()
        this.closeContactInformationDialog()
      }).catch((error) => {
        console.log("editWebsite error => ", error)
      })
    },
    async save() {
      let formValidated = this.$refs.form.validate()
      if (!formValidated) return
      let data = Object.assign({}, this.editedItem)
      await editWebsite(data).then(() => {
        this.getWebsites()
        this.close()
      }).catch(error => {
        console.log("editWebsite error => ", error)
      })
    }
  },
}
</script>

<style scoped>
.connects-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
}
.single-connect-wrapper {
  width: 300px;
  min-height: 200px;
  /* background-color: rgb(186, 198, 211); */
  border-radius: 5px;
  margin: 10px;
  padding: 16px;
}
</style>